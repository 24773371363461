const IMG_BASE = "https://resource.fzhitong.cn"
const Constant = {
    DEFAULT_COMPANY_GUID: '', //公司guid 打包修改
    DEFAULT_CITY_REGION: "010100",
    DEFAULT_CITY_CODE: "156511100",
    DEFAULT_CITY_NAME: '乐山市',
    CHOOSELOCATION: "CHOOSELOCATION",
    CUSTOMER: "CUSTOMER",
    DEFAULT_CITY_LAT: 29.566132,
    DEFAULT_CITY_LNG: 103.768902,
    DEFAULT_HOUSE: 'https://resource.fzhitong.cn/house_cover_img_new_v1.png',
    DEFAULT_AVATAR: 'https://resource.fzhitong.cn/default-customerAvatar.png',

    BAIDU_AK: "cgR5juzHQGCiXxUutZqKf82L93QIONiG",

    SECOND_HISTORY: "SECOND_HISTORY", // 搜索历史
    SEARCH_RECORDS: "SEARCH_RECORDS", //我的搜索
    RENT_HISTORY: 'RENT_HISTORY',
    NEW_ESTATE_HISTORY: "NEW_ESTATE_HISTORY",
    CITY_COMPANY: 'CITY_COMPANY',
    IS_GET_LOCATION: 'IS_GET_LOCATION',
    IS_REFRESH_PAGE: 'IS_REFRESH_PAGE',
    IS_SORCE_ERP: "IS_SORCE_ERP",

    AGREEMENT_USE: "https://api.fzhitong.cn/api/content/customer/useAgreement",//用户协议
    AGREEMENT_PRIVACY: "https://api.fzhitong.cn/api/content/agent/agreement/privacy",//隐私政策

    DEV_MODE: "development",//开发环境配置
    PRO_MODE: "production",//线上环境配置
    MODE: "production",
    ARTICLE_URL: '/article/web/article/detail/',

    IMG_BASE,
    IMG_OTHER_URL: IMG_BASE + "/zhaofang/other",
    IMG_ICON_URL: IMG_BASE + "/zhaofang/icon",
};

module.exports = Constant