/**
 * 初始化 dayjs 相关配置
 */
import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'

// 配置使用处理相对时间
dayjs.extend(relativeTime)

// 配置使用中文语言包
dayjs.locale('zh-cn')

Vue.filter('relativeTime', value => {
    return dayjs(value).from(dayjs())
})

Vue.filter('datetime', (value, format = 'YYYY-MM-DD') => {
    return dayjs(value).format(format)
})

 // 例如日期格式化
 // console.log(dayjs().format('YYYY^MM-DD HH:mm:ss'))
 // console.log(dayjs('2020-05-13 15:38:30').from(dayjs()))
