<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getCompanyConfig } from "./store/index";
export default {
  name: "App",
  methods:{
    addClassName() {
        const current = getCompanyConfig()
        document.getElementById('app').setAttribute('class', `theme_${current.themeClassName}`)
    }
  },
  mounted() {
    this.addClassName()
  },
};
</script>

<style  lang="less">

</style>
