import Vue from 'vue'
import Vuex from 'vuex'
import { setItem, getItem } from '../utils/storage'
import Constant from '../common/Constant'
Vue.use(Vuex)

export const getCompanyConfig = () => {
    const map = [
        {
            themeClassName: 'xiaozhi', //主题类名
            themeColor: '#00AE66', //主题颜色
            suffixUrl: '', //协议后缀
            appId: 1, //小程序配置 2 小智找房
            companyGuid: '',
            appName: '小智找房'
        },
        {
            themeClassName: 'tiantian',
            themeColor: '#9a0000',
            suffixUrl: '/tiantian',
            appId: 3,
            companyGuid: '95ba4e1747bf11ea95a700163e068943',
            appName: '天天选房'
        },
        {
            themeClassName: 'tiancheng',
            themeColor: '#043790',
            suffixUrl: '/tiancheng',
            appId: 4,
            companyGuid: 'df9804477ac111edaf4a00163e3670e0',
            appName: '甜城找房'
        },
    ]
    const current = map.find(_ => _.companyGuid === Constant.DEFAULT_COMPANY_GUID)
    return current
}
export default new Vuex.Store({
    state: {
        user: getItem(Constant.CUSTOMER),
        showLogin: false, // 显示登录按钮
        showSearchTip: false, //是否显示搜索框
        searchRecords: getItem(Constant.SEARCH_RECORDS) || [], //我的搜索
        location: getItem(Constant.CHOOSELOCATION) || {
            cityCode: Constant.DEFAULT_CITY_CODE,
            cityName: Constant.DEFAULT_CITY_NAME,
            latitude: Constant.DEFAULT_CITY_LAT,
            longitude: Constant.DEFAULT_CITY_LNG
        },
        defaultRealHouseName: "真房源",
        companyConfig: { // 公司配置
            tel: '028 6463 6764',
            firstName: '小智',
            lastName: '找房',
            whiteLogo: Constant.IMG_ICON_URL + '/app_icon_logo_white.png',
            greenLogo: Constant.IMG_ICON_URL + '/app_icon_logo_green.png',
            downloadUrl: Constant.IMG_BASE + '/xiaozhizhaofang.png',
            miniLogo: Constant.IMG_ICON_URL + '/app_icon_logo_white_mini.png',
            webSiteInfo: {
                name: '成都智住科技有限公司',
                address: '成都市高新区环球中心E3, 2-1 - 911',
                filing: ' 蜀ICP备15016750号 - 6',
                date: '2010 - 2022',
                webName: '小智找房'
            },
            ...getCompanyConfig()
        },
        cityCompany: getItem(Constant.CITY_COMPANY) || {},
        isGetLocation: getItem(Constant.IS_GET_LOCATION),
        isRefreshPage: getItem(Constant.IS_REFRESH_PAGE),
        isSourceERP: getItem(Constant.IS_SORCE_ERP) || false
    },
    mutations: {
        updateState(state, { data, key }) {
            state[key] = data
        },
        saveLocation(state, data) {
            state.location = data
            setItem(Constant.CHOOSELOCATION, state.location)
        },

        saveUser(state, data) {
            state.user = data
            setItem(Constant.CUSTOMER, state.user)
        },
        saveSearchRecords(state, data) {
            state.searchRecords = data
            setItem(Constant.SEARCH_RECORDS, state.searchRecords)
        }

    },
    actions: {

    },
    modules: {
    }
})
